<template>
  <b-sidebar
    id="cfdi-sidebar-reports"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    shadow
    backdrop
    no-header
    right
    width="640px"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('REPORTS') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >
      <div class="divider my-0" >
        <div class="divider-text" >
          {{ t('Rango y Tipo') }}              
        </div>
      </div>
      <b-form-row>
        <b-col cols="4">
          <b-form-group
            :label="t('Fecha Inicial')"
            label-for="report-start-date"
          >
            <b-form-datepicker
              id="report-start-date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="es"
              size="sm"
              placeholder=""
              v-model="reportStartDate"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            :label="t('Fecha Final')"
            label-for="report-end-date"
          >
            <b-form-datepicker
              id="report-end-date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="es"
              size="sm"
              placeholder=""
              v-model="reportEndDate"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              :label="t('Tipo de Comprobante')"
              label-for="report-cfdi-type"
            >
              <b-form-select
                id="report-cfdi-type"
                v-model="reportCfdiType"
                text-field="v"
                label-field="v"
                size="sm"
              >
                <option v-for="option in cfdiTypeList" :key="option.k" :value="option">
                  {{ option.v }}
                </option>
              </b-form-select>
            </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="4">
        </b-col>
        <b-col cols="4">
          <b-button
            :disabled="!buildReportAvailable"
            type="submit"
            variant="primary"
            class="w-100"
          >
            {{ t('Generar') }}
          </b-button>
        </b-col>
        <b-col cols="4">
          
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="12">
          &nbsp;
        </b-col>
      </b-form-row>

      <div class="divider my-0" >
        <div class="divider-text" >
          {{ t('Historico') }} 
        </div>
      </div>


      
        
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BOverlay, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BButton, BFormRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import reportsStoreModule from './reportsStoreModule'
import store from '@/store'
import c_tipocomprobante from '@/@catalogs/sat/c_tipocomprobante'


export default {
  components: {
    BSidebar,
    BForm,
    BFormRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormDatepicker,

    vSelect,
  },
  props: {
    
  },
  directives: {
    Ripple,
  },
  watch: {
    
  },
  data() {
    return {
      paymentAmount: 0.0,
      paymentCurrencyExchange: '1.0',
      paymentCurrencyInvoiceExchange: '',
      paymentCurrencyMXNExchange: '',

      reportStartDate: '',
      reportEndDate: '',
      reportCfdiType: '',

      reportList: [],

      cfdiTypeList: [{ k: 'fg.tc.all', v: 'Todos', e: 'true', ksat: 'A', vsat: 'Todos' }, ...c_tipocomprobante ],
      
      onEmmitBusy: false,
    }
  },
  computed: {
    buildReportAvailable: function() {
      return this.reportStartDate && this.reportEndDate && this.reportCfdiType
    },
  },
  methods: {
    list: function() {
      this.$store.dispatch('fg-feature-reports/list', { type: 'report.simple' })
        .then((response) => {
          this.reportList = response.data.report.list 
        })
    },
    build: function() {
      this.onEmmitBusy = true
      // Hay que validar antes el envio
      this.$store.dispatch('fg-feature-reports/build', { type: 'report.simple', meta: { startDate: this.reportStartDate, endDate: this.reportEndDate, cfdiType: this.reportCfdiType } })
        .then((response) => {

          this.onEmmitBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Reports'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('Report Generated, with id ') + response.data.report.id,
              },
            })
        })
        .catch( (error) => {
            /** Nacho's here we need the beautifull experience */
            this.onEmmitBusy = false
            /** Aqui incorporar la beautiful experience */
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Reports'),
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                text: this.$t(error.error.message),
              },
            })
        })
      }
  },
  setup() {
    const APP_FEATURE_MODULE_NAME = 'fg-feature-reports'

    // Register module
    if (!store.hasModule(APP_FEATURE_MODULE_NAME)) store.registerModule(APP_FEATURE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_FEATURE_MODULE_NAME)) store.unregisterModule(APP_FEATURE_MODULE_NAME)
    })

    /** lsandoval: 09/02/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    
    return {
     t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
