<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-left justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="t('SearchRFC')"
              :formatter="fmtUppercaseAll"
            />
          </b-col>

          <!-- Search -->
         <b-col
            cols="8"
            md="2"
          >
            <template v-if="session.business.address.main.zip === ''">
              <b-button
                variant="danger"
                :to="{ name: 'fg-account-settings'}"
              >
                <span class="text-nowrap">{{ $t('Complete Profile First') }}</span>
              </b-button>
            </template>
            <template v-else>
              <b-button
                v-if="!isFoliosScope"
                variant="primary"
                @click="addCfdiFromContact(searchQuery)"
              >
                <span class="text-nowrap">{{ $t('Add Invoice') }}</span>
              </b-button>
            </template>
            
            <!--<template v-if="canCancelMultiple">
              <b-button
                style="margin-left: 10px;"
                variant="primary"
                @click="addCfdiFromContact(searchQuery)"
              >
                <span class="text-nowrap">{{ $t('Cancel Multiple') }}</span>
              </b-button>
            </template>-->

            <template v-if="canUseAsDiscountMultiple">
              <b-button
                style="margin-left: 10px;"
                variant="primary"
                @click="addCfdiFromDiscount(listCfdis)"
              >
                <span class="text-nowrap">{{ $t('Use as discount') }}</span>
              </b-button>
            </template>

          </b-col>
          <b-col
            cols="12"
            md="7"
            style="display: flex;">
            <!-- We need to add multiple buttons for different searches -->
            <b-row style="width: 100%">
              <b-col cols="12" md="8" style="display: flex;">
                <b-button-group size="sm" v-if="isScopeEnabled" style="margin-left: auto; width: 80%;">
                  <b-button
                  variant="outline-info"
                  :pressed="selEmmited"
                  style="width: 25%"
                  @click="listCfdiWindow(0)"
                  >
                    <span class="text-nowrap">{{ $t('Non Emmited') }}</span>
                  </b-button>
                  <b-button
                  variant="outline-info"
                  :pressed="selMonthCurrent"
                  style="width: 25%"
                  @click="listCfdiWindow(1)"
                  >
                    <span class="text-nowrap">{{ $t('Current Month') }}</span>
                  </b-button>
                  <b-button
                  variant="outline-info"
                  :pressed="selMonthOneBefore"
                  style="width: 25%"
                  @click="listCfdiWindow(2)"
                  >
                    <span class="text-nowrap">{{ $t('Last Month') }}</span>
                  </b-button>
                  <b-button
                  variant="outline-info"
                  :pressed="selMonthTwoBefore"
                  style="width: 25%"
                  @click="listCfdiWindow(3)"
                  >
                    <span class="text-nowrap">{{ $t('Two Months Before') }}</span>
                  </b-button>
                </b-button-group>
              </b-col>
             
              <b-col cols="12" md="3" style="display: flex;">
                <feather-icon v-if="!selEmmited && isScopeEnabled && dataMeta.of > 0"
                  icon="SaveIcon"
                  variant="danger"
                  size="24"
                  style="margin: 6px; cursor: pointer;"
                  v-b-tooltip.hover.top="t('tpdownloadreport')"
                   @click="downloadReport"
                />
              </b-col>
              <b-col cols="12" md="1" style="display: flex;">
               <!--
                <b-button
                  v-b-toggle.cfdi-sidebar-reports
                  variant="primary"
                >
                  <span class="text-nowrap">{{ $t('Reports') }}</span>
                </b-button>-->
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCfdiListTable"
        class="position-relative"
        :items="fetchCfdis"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('No matching records found')"
        :no-select-on-click="listScope === 'work'" 
        select-mode="range"
        primary-key="uuid"
        responsive="sm"
        
        selectable
        show-empty
        @row-selected="onCfdiSelected" 
      >
        <template #head(folio)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(customer)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(taxid)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(edate)="data"> <!--ILL: 090322 SE CAMBIA POR CD -->
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(status)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(total)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <template #head(actions)="data">
          <span class="text-secondary">{{ t(data.label) }}</span>
        </template>

        <!-- Columnn: Folio -->
        <template #cell(folio)="data">
          <div class="text-nowrap">
            <template v-if="data.item.folio === ''">
              <b-badge
                pill
                variant="light-primary"
              >
                {{ $t('Non Emmited') }}
              </b-badge>
            </template>
            <template v-else>
              <span class="align-text-top text-capitalize">{{ data.item.serie || '' }} {{ data.item.folio }}</span>
            </template> 
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(customer)="data">
          <b-media vertical-align="center">
            <b-link :id="`cfdi-row-${data.item.uuid}-name`" class="font-weight-bold d-block text-nowrap">
              {{ data.item.c_name.length > 45 ? data.item.c_name.substr(0,45) + '. . .' : data.item.c_name}}
              <!--{{ data.item.c_name }}-->
            </b-link>
            <b-tooltip v-if="data.item.c_name.length > 45"
              class="cursor-pointer"
              :target="`cfdi-row-${data.item.uuid}-name`"
            >
              <span style="font-size: 7.5pt;">{{ data.item.c_name }}</span>
            </b-tooltip>            
            <small class="text-black">{{ data.item.c_email }}</small>
          </b-media>
        </template>

        <!-- Column: TaxId -->
        <template #cell(taxid)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.c_taxid }}</span>
          </div>
        </template>

        <!-- Column: Fecha de Creación -->
        <template #cell(edate)="data">
          <div class="text-nowrap">
            <template v-if="data.item.edate === ''">
              <b-badge
                pill
                variant="light-primary"
              >
                {{ $t('Non Emmited') }}
              </b-badge>
            </template>
            <template v-else><!--ILL:250422 SE HOMOLOGA FORMATO DE FECHA EN COLUMNA FECHA (DE EMISIÓN) -->
              <span class="align-text-top text-capitalize">{{ Intl.DateTimeFormat('es-MX', { day:'2-digit', month:'2-digit', year:'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(data.item.edate)) }}</span>
            </template> 
          </div>
        </template>

        <template #cell(status)="data">
          <template v-if="data.item.wlc !== 'created'">
            <b-row>
              <b-col cols="1" v-if="data.item.wlc !== 'created'">
                <feather-icon
                  :id="`cfdi-row-${data.item.uuid}-invoice-payment`"
                  :icon="data.item.wpt === 'PUE' ? 'FileIcon' : 'FilePlusIcon'"
                  size="16"
                />
                <b-tooltip
                  :title="data.item.wpt === 'PUE' ? t('PUE') : t('PPD')"
                  class="cursor-pointer"
                  :target="`cfdi-row-${data.item.uuid}-invoice-payment`"
                />               
              </b-col>
                <b-col cols="2" v-if="data.item.wlc === 'cfdi.complete'">
                  <b-badge
                    pill
                    variant="light-success"
                  >
                    {{ $t('cfdi.complete') }}
                  </b-badge>
                  
                </b-col>
                <b-col cols="2" v-if="data.item.wlc === 'cfdi.partial'">
                  <b-badge
                    pill
                    variant="light-secondary"
                  >
                    {{ $t('cfdi.partial') }}
                  </b-badge>
                </b-col>
                <b-col cols="2" v-if="data.item.wlc === 'cfdi.cancel.start'">
                  <b-badge
                    pill
                    variant="light-warning"
                  >
                    {{ $t('cfdi.cancel.start') }}
                  </b-badge>
                </b-col>   
                <b-col cols="2" v-if="data.item.wlc === 'cfdi.cancel.accepted'">
                  <b-badge
                    pill
                    variant="light-danger"
                  >
                    {{ $t('cfdi.cancel.accepted') }}
                  </b-badge>
                </b-col>  
                <b-col cols="2" v-if="data.item.wlc === 'cfdi.cancel.rejected'">
                  <b-badge
                    pill
                    variant="light-warning"
                  >
                    {{ $t('cfdi.cancel.rejected') }}
                  </b-badge>
                </b-col>              
            </b-row>
            <b-row v-if="data.item.feat && data.item.feat.fgFeatureCollections" style="padding-top: 2px;">
              <b-col cols="1">
                <feather-icon
                  :id="`cfdi-row-${data.item.uuid}-collections`"
                  icon="HashIcon"
                  size="16"
                />
              </b-col>  
              <b-col cols="2">
                <b-badge 
                  pill
                  variant="light-dark"
                >
                  {{ $t(data.item.feat.fgFeatureCollections.status) }}
                </b-badge>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <b-badge
              pill
              variant="light-dark"
            >
              {{ $t('worker.created') }}
            </b-badge>
          </template>
        </template>

        <template #cell(total)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.total }} {{ data.item.wcu && data.item.wcu !== 'MXN' ? '(' + data.item.wcu + ')' : '' }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row >
            <b-col cols="1" v-if="data.item.status === 'created'"> <!--ILL 220222 acción para editar, solo aplica para comprobantes no enviados -->
              <feather-icon
                :id="`cfdi-row-${data.item.uuid}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="18"
                @click="fetchCfdi(data.item.uuid)"
              />
              <b-tooltip
                :title="t('Edit')"
                class="cursor-pointer"
                :target="`cfdi-row-${data.item.uuid}-edit-icon`"
              />
            </b-col>
            <b-col cols="1" v-if="data.item.status === 'created'">
              <feather-icon
                :id="`cfdi-row-${data.item.uuid}-invoice-preview`"
                icon="ClipboardIcon"
                class="cursor-pointer"
                size="18"
                @click="previewCfdi(data.item.uuid)"
              />
              <b-tooltip
                :title="t('invoicepreview')"
                class="cursor-pointer"
                :target="`cfdi-row-${data.item.uuid}-invoice-preview`"
              />
            </b-col>              
            <b-col cols="1" v-if="data.item.status === 'created'"> <!--ILL 220222 acción para editar, solo aplica para comprobantes no enviados -->
              <feather-icon
                :id="`cfdi-row-${data.item.uuid}-delete-icon`"
                icon="Trash2Icon"
                class="cursor-pointer"
                size="18"
                @click="deleteCfdi(data.item.uuid)"
              />
              <b-tooltip
                :title="t('Delete')"
                class="cursor-pointer"
                :target="`cfdi-row-${data.item.uuid}-delete-icon`"
              />
            </b-col>  
            <b-col cols="1" v-if="data.item.status === 'emmited' || data.item.status === 'canceled.started' || data.item.status === 'canceled.accepted'"> <!--ILL 220222 acción para ver, solo para comprobantes enviados -->
              <feather-icon
                :id="`cfdi-row-${data.item.uuid}-invoice-view`"
                icon="EyeIcon"
                class="cursor-pointer"
                size="18"
                @click="fetchEmmitedCfdi(data.item.uuid)"
              />
              <b-tooltip
                :title="t('invoiceview')"
                class="cursor-pointer"
                :target="`cfdi-row-${data.item.uuid}-invoice-view`"
              />
            </b-col>
            <b-col cols="1" v-if="data.item.wlc === 'cfdi.complete' || data.item.wlc === 'cfdi.partial'"> 
              <feather-icon
                :id="`cfdi-row-${data.item.uuid}-invoice-duplicate`"
                icon="CopyIcon"
                class="cursor-pointer"
                size="18"
                @click="duplicateCfdi(data.item.uuid)"
              />
              <b-tooltip
                :title="t('duplicate')"
                class="cursor-pointer"
                :target="`cfdi-row-${data.item.uuid}-invoice-duplicate`"
              />
            </b-col>                                                                                          
            <b-col cols="6">
            </b-col>
          </b-row>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Showing') }} {{ dataMeta.from }} {{ t('to') }} {{ dataMeta.to }} {{ t('of') }} {{ dataMeta.of }} {{ t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCfdis"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <cfdi-sidebar-reports/>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButtonGroup, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import moment from 'moment'
import { ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { avatarText, cFormatter, dFormatter } from '@core/utils/filter'
import useCfdiList from './useCfdiList'
import cfdiStoreModule from './cfdiStoreModule'
import CfdiListAddNew from './CfdiListAddNew.vue'
import CfdiListEdit from './CfdiListEdit.vue'
import CfdiSidebarReports from './CfdiSidebarReports.vue'
import { useThrottledRefHistory } from '@vueuse/core'

export default {
  components: {
    CfdiListAddNew,
    CfdiListEdit,
    CfdiSidebarReports,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mounted() {
    this.$root.$on('session-refresh',(message) => {
      this.session = JSON.parse(localStorage.getItem('session'))
      if (this.$store.hasModule(this.USER_APP_STORE_MODULE_NAME)) {
        //this.refetchData()
      }
    })
  },
  watch: {
    $route(to, from) {
      if(!to.query.taxid){
        this.searchQuery = ''
      }
    }
  },
  computed: {
    canCancelMultiple: {
      get() {
        if(!this.isFoliosScope || this.listCfdis.length === 0) return false
        return true
      }
    },
    canUseAsDiscountMultiple: {
      get() {
        if(!this.isFoliosScope || this.listCfdis.length === 0) return false

        let currentCustomerTaxid = ''
        let currentCustomerPaymentCurrency = ''

        for(let i = 0; i < this.listCfdis.length; i++){
          if(currentCustomerTaxid === '') {
            currentCustomerTaxid = this.listCfdis[i].c_taxid
          } else {
            if(currentCustomerTaxid !== this.listCfdis[i].c_taxid){
              return false
            }
          }

          if(currentCustomerPaymentCurrency === '') {
            currentCustomerPaymentCurrency = this.listCfdis[i].wcu
          } else {
            if(currentCustomerPaymentCurrency !== this.listCfdis[i].wcu){
              return false
            }
          }
        }

        return true
      }
    }
  },
  props:{
    taxid: String,
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'fg-cfdi'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, cfdiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    let isAddNewCfdiSidebarActive = ref(false)
    let isEditCfdiSidebarActive = ref(false)
    /** lsandoval: 13/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    /** lsandoval: 17/02/2022 We add the session to handle emisor data */
    let session = JSON.parse(localStorage.getItem('session'))

    const vm = getCurrentInstance().proxy

    const {
      fetchCfdis,
      tableColumns,
      listScope,
      isScopeEnabled,
      isFoliosScope,
      perPage,
      currentPage,
      totalCfdis,
      listCfdis,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCfdiListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveCfdiTypeVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useCfdiList()

    if(props.taxid){
      searchQuery.value = props.taxid
    }

    return {
      // Sidebar
      isAddNewCfdiSidebarActive,
      isEditCfdiSidebarActive,

      fetchCfdis,
      //fetchCfdi,
      tableColumns,
      listScope,
      isScopeEnabled,
      /** Enabled only if the result was product of searching folios */      
      isFoliosScope,
      perPage,
      currentPage,
      totalCfdis,
      listCfdis,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCfdiListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveCfdiTypeVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      cFormatter,
      dFormatter,

      session,
      // i18n
      t,

      USER_APP_STORE_MODULE_NAME
    }
  },
  data() {
    return {
      selEmmited: false,
      selMonthCurrent: true,
      selMonthOneBefore: false,
      selMonthTwoBefore: false
    }
  },
  methods: {
    // ILL: 060423 para que la cadena de busqueda se transforme a mayusculas
    fmtUppercaseAll(value) {
        let v = value.toUpperCase()        
        return v
    },      
    fetchCfdi (uuid) {
      this.$router.replace({ name: 'fg-cfdi-edit', params: { uuid: uuid }})
        .then(() => {
          
        })
    },
    deleteCfdi (uuid) {
      this.$store.dispatch('fg-cfdi/deleteCfdi', { uuid: uuid })
        .then((res) => {
          this.refetchData()
        })
    },
    fetchEmmitedCfdi (uuid) {
      this.$router.replace({ name: 'fg-cfdi-view', params: { uuid: uuid }})
        .then(() => {
          
        })
    },
    previewCfdi (uuid) {
      this.$router.replace({ name: 'fg-cfdi-preview', params: { uuid: uuid }})
        .then(() => {
          
        })
    },
    duplicateCfdi (uuid) {
      this.$router.replace({ name: 'fg-cfdi-duplicate', params: { duuid: uuid }})
        .then(() => {
          
        })
    },
    downloadReport() {
      this.$store.dispatch('fg-cfdi/fetchReport',{ scope: this.listScope })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report_' + this.session.business.taxid + '_' + this.listScope + '.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    listCfdiWindow ( a ) {
      let cd = new Date()
      let cd_m = cd.getMonth()
      let cd_y = cd.getFullYear();

      let cm0 = moment().format('MMYYYY')
      let cm1 = moment().subtract(1, 'months').format('MMYYYY')
      let cm2 = moment().subtract(2, 'months').format('MMYYYY')

      switch(a) {
        case 0:
          this.listScope = 'work'
          this.selEmmited = true
          this.selMonthCurrent = false
          this.selMonthOneBefore = false
          this.selMonthTwoBefore = false
          break;
        case 1: 
          this.listScope = cm0
          this.selEmmited = false
          this.selMonthCurrent = true
          this.selMonthOneBefore = false
          this.selMonthTwoBefore = false
          break;
        case 2: 
          this.listScope = cm1
          this.selEmmited = false
          this.selMonthCurrent = false
          this.selMonthOneBefore = true
          this.selMonthTwoBefore = false
          break;
        case 3: 
          this.listScope = cm2
          this.selEmmited = false
          this.selMonthCurrent = false
          this.selMonthOneBefore = false
          this.selMonthTwoBefore = true
          break;
      }
    },
    addCfdiFromContact (uuid) {
      if(uuid){
        this.$router.replace({ name: 'fg-cfdi-add-from-contact', params: { cuuid: uuid }})
        .then(() => {})
      }else{
        this.$router.replace({ name: 'fg-cfdi-add'})
        .then(() => {})
      }
    },
    addCfdiFromDiscount ( uuidList ) {
      let list = []
      uuidList.forEach(element => {
        list.push(element.uuid) 
      })

      this.$router.replace({ name: 'fg-cfdi-add-withdiscount-oflist', params: { u: list }})
        .then(() => {})
    },
    onCfdiSelected (cfdis) {
      console.log('onCfdiSelected',cfdis)
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
