<template>
  <b-sidebar
    id="edit-contact-sidebar"
    :visible="isEditContactSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="600px"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-contact-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          <!-- Edit Contact {{ contactData.taxid }} ILL: 270122 no trae la variable taxid--> 
          {{ t('tedit') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Tax ID -->
          <validation-provider
            #default="validationContext"
            name="RFC"
            rules="required"
          >
            <b-form-group
              :label="t('Tax ID')"
              label-for="taxid"
              :invalid-feedback="t('taxidr')"

            >
              <b-form-input
                id="taxid"
                v-model="contactData.taxid"
                disabled
                autofocus
                :state="getValidationState(validationContext)"
                :formatter="fmtUppercase"
                trim
                placeholder="XAXX010101AAA"
                @change="contactData.taxid && contactData.taxid.length === 12 ? contactData.type = 'pm' : contactData.type = 'pf' "
              />

              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Business Name"
            rules="required"
          >
            <b-form-group
              :label="t('Business Name')"
              label-for="username"
              :invalid-feedback="t('bnamereq')"
            >
              <b-form-input
                id="username"
                v-model="contactData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              :label="t('Email')"
              label-for="email"
              :invalid-feedback="t('emailr')"
            >
              <b-form-input
                id="email"
                v-model="contactData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required"
          >
            <b-form-group
              :label="t('Phone')"
              label-for="phone"
              :invalid-feedback="t('phoner')"
            >
              <b-form-input
                id="phone"
                v-model="contactData.phone"
                :state="getValidationState(validationContext)"
                type="number"
                :formatter="fmt10num"                
                trim
              />
              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- TaxPayer Type -->
          <validation-provider
            #default="validationContext"
            name="TaxPayer Type"
            rules="required"
          >
            <b-form-group
              :label="t('TaxPayer Type')"
              label-for="taxpayerType"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.taxertype"
                text-field="v"
                label="v"
                :options="taxpayerList.filter(c => c.pt.find(o => o === contactData.type))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Invoice Use"
            rules="required"
          >
            <b-form-group
              :label="t('Invoice Use')"
              label-for="invoiceUse"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.invoiceuse"
                text-field="v"
                label="v"
                :options="invoiceuseList.filter(c => (c.pt.find(o => o === contactData.type) && c.sr.find(u => u === contactData.taxertype.k)))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="divider my-2">
            <div class="divider-text">
              {{ t('Address') }}
            </div>
          </div>

          <!-- ZIP -->
          <validation-provider
            #default="validationContext"
            name="ZIP"
            rules="required"
          >
            <b-form-group
              :label="t('Zip')"
              label-for="zip"
              :invalid-feedback="t('zipr')"
            >
              <b-form-input
                id="zip"
                v-model="contactData.address.zip"
                :state="getValidationState(validationContext)"
                type="number"
                :formatter="fmt5num"                
                trim
              />

              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <b-form-group
            label-for="countryList"
            :label="t('Country')"
          >
            <v-select
              id="countryList"
              disabled
              v-model="contactData.address.country"
              text-field="v"
              label="v"
              :options="countryOption"
            />
          </b-form-group>

          <!-- State -->
          <b-form-group
            label-for="stateList"
            :label="t('State')"
          >
            <v-select
              id="stateList"
              disabled
              v-model="contactData.address.state"
              text-field="v"
              label="v"
              :options="stateOption"
            />
          </b-form-group>

          <!-- Town -->
          <b-form-group
            label-for="townList"
            :label="t('Town')"
          >
            <v-select
              id="townList"
              disabled
              v-model="contactData.address.town"
              text-field="v"
              label="v"
              :options="townOption"
            />
          </b-form-group>

          <!-- Suburb -->
          <b-form-group
            label-for="suburbList"
            :label="t('Suburb')"
          >
            <v-select
              id="suburbList"
              v-model="contactData.address.suburb"
              text-field="v"
              label="v"
              :options="suburbOption"
            />
          </b-form-group>

          <!-- Street -->
          <b-form-group
            label-for="address-street"
            :label="t('Street')"
          >
            <b-form-input
              id="street"
              v-model="contactData.address.street"
              placeholder="Street and Number"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ t('Update') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import invoiceuseList from '@/@catalogs/sat/c_usocfdi'
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditContactSidebarActive',
    event: 'update:is-edit-contact-sidebar-active',
  },
  props: {
    isEditContactSidebarActive: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      stateOption: [],
      townOption: [],
      suburbOption: [],
      countryOption: [{ k: 'MX', v: 'Mexico' }],
      invoiceuseList,
      taxpayerList,
    }
  },
  setup(props, { emit }) {
    const blankContactData = (data) => {
      let o = {
          taxid: data.taxid ? data.taxid: '', // RFC
          name: data.name ? data.name: '', // Razon Social
          type: data.type ? data.type: '', // Tipo de Persona
          taxertype: { // Regimen Fiscal
            k: data.taxertype && data.taxertype.k ? data.taxertype.k: '',
            v: data.taxertype && data.taxertype.v ? data.taxertype.v: ''
          },
          invoiceuse: { // Uso de la Factura
            k: data.invoiceuse && data.invoiceuse.k ? data.invoiceuse.k: '',
            v: data.invoiceuse && data.invoiceuse.v ? data.invoiceuse.v: ''
          },
          email: data.email ? data.email: '', // Correo Electronico
          phone: data.phone ? data.phone: '', // Telefono
          address: {
            street: data.address && data.address.street ? data.address.street: '',
            suburb: {
              k: data.address && data.address.suburb ? data.address.suburb.k: '',
              v: data.address && data.address.suburb ? data.address.suburb.v: ''
            },
            town: {
              k: data.address && data.address.town ? data.address.town.k: '',
              v: data.address && data.address.town ? data.address.town.v: ''
            },
            state: {
              k: data.address && data.address.state ? data.address.state.k: '',
              v: data.address && data.address.state ? data.address.state.v: ''
            },
            country: {
              k: data.address && data.address.country ? data.address.country.k: '',
              v: data.address && data.address.country ? data.address.country.v: ''
            },
            meta: {
              sat: {
                country: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.country: '',
                state: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.state: '',
                town: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.town: '',
                suburb: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.suburb: '',
                st: {
                  m: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.m: 0,
                  a: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.a: '',
                  h: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.h: '',
                  gmt: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.st.gmt: -5
                },
                wt: {
                  m: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.m: 0,
                  a: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.a: '',
                  h: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.h: '',
                  gmt: data.address && data.address.meta && data.address.meta.sat ? data.address.meta.sat.wt.gmt: -6
                }
              }
            },
            zip: data.address && data.address.zip ? data.address.zip: '',
          },
        }

        return o;
    }

    let contactUUID = ref(new String())
    let contactData = ref(JSON.parse(JSON.stringify(blankContactData({}))))
    let isEditContactSidebarActive = ref(false)

    const resetcontactData = () => {
      contactData.value = JSON.parse(JSON.stringify(blankContactData({})))
    }

    const fetchContact = (uuid) => {
      // Hay que validar antes el envio
      console.group('fg.views.contactListEdit fetchContact')
      console.log('uuid',uuid)
      console.groupEnd();
      store.dispatch('fg-contact/fetchContact', { uuid: uuid })
        .then((res) => {
          contactData.value = blankContactData(res.data) 
          contactUUID.value = uuid
          emit('update:is-edit-contact-sidebar-active', true)
          //isEditContactSidebarActive.value = true
        })
    }

    const onSubmit = () => {
      // Hay que validar antes el envio
      console.group('fg.apps.contact.edit')
      console.log('arguments',contactUUID,contactData)
      console.groupEnd();
      store.dispatch('fg-contact/updateContact', { uuid:contactUUID.value, contact: contactData.value })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-contact-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontactData)

    /** lsandoval: 27/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    return {
      contactData,
      fetchContact,
      onSubmit,

      //isEditContactSidebarActive,

      refFormObserver,
      getValidationState,
      resetForm,

      t,
    }
  },
  methods: {
    fmtUppercase(value) {
        let v = value.toUpperCase()
        v = v.length > 13 ? v.substr(0,13) : v
        return v
    },
    // ILL: 270122 para que la longitud del campo phone sea max = 10
    fmt10num(value) {        
        let v=value
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },
    // ILL: 270122 para que la longitud del campo zip sea max = 5
    fmt5num(value) {        
        let v=value
        v = v.length > 5 ? v.substr(0,5) : v
        return v
    },
    checkZip (zip) {
      // Hay que validar antes el envio
      this.$store.dispatch('fg-contact/lookupZip', zip )
        .then((response) => {
          const bundle = response.data.bundle
          
          this.stateOption = [bundle.state]
          this.townOption = [bundle.town]
          this.suburbOption = [...bundle.suburbs]

          this.contactData.address.state = bundle.state
          this.contactData.address.town = bundle.town
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
