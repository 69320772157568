import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    build(ctx, report ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/feature/reports/build', { report })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    list(ctx, report ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/feature/reports/list', { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
