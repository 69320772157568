<template>
  <b-sidebar
    id="add-new-contact-sidebar"
    :visible="isAddNewContactSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="600px"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-contact-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add New Contact') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Tax ID -->
          <validation-provider
            #default="validationContext"
            name="RFC"
            rules="required"
          >
            <b-form-group
              :label="t('Tax ID')"              
              label-for="taxid"
              :invalid-feedback="t('taxidr')"
            >
              <b-form-input
                id="taxid"
                v-model="contactData.taxid"
                autofocus
                :state="getValidationState(validationContext)"
                :formatter="fmtUppercase"
                max="13"
                trim
                placeholder="XAXX010101AAA"
                @change="contactData.taxid && contactData.taxid.length === 12 ? contactData.type = 'pm' : contactData.type = 'pf' "
              />

              <!-- <b-form-invalid-feedback> ILL: 270122 se quitan de todos los campos los feedback, para posterior revisión de como manejarlos
                {{ validationContext.errors[0] }} ILL: 270122 se remplaza directo por :invalid-feedback
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Business Name"
            rules="required"
          >
            <b-form-group
              :label="t('Business Name')"
              label-for="username"
              :invalid-feedback="t('bnamereq')"
            >
              <b-form-input
                id="username"
                v-model="contactData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              :label="t('Email')"
              label-for="email"                            
              v-b-tooltip.hover.top="t('temail')"              
              :invalid-feedback="t('emailr')"
              >
                  <b-form-input
                    id="email" 
                    v-model="contactData.email"
                    :state="getValidationState(validationContext)"                
                    trim                  
                  >              
                </b-form-input>
              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
            <!--<feather-icon icon="SearchIcon" />-->
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required"
          >
            <b-form-group
              :label="t('Phone')"
              label-for="phone"
              :invalid-feedback="t('phoner')"
            >
              <b-form-input
                id="phone"
                v-model="contactData.phone"
                :state="getValidationState(validationContext)"
                type="number"
                :formatter="fmt10num"
                trim
              />
              
              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- TaxPayer Type -->
          <validation-provider
            #default="validationContext"
            name="TaxPayer Type"
            rules="required|catalog"
          >
            <b-form-group
              :label="t('TaxPayer Type')"
              label-for="taxpayerType"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.taxertype"
                :state="getValidationState(validationContext)"
                text-field="v"
                label="v"
                :options="taxpayerList.filter(c => c.pt.find(o => o === contactData.type))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Invoice Use"
            rules="required|catalog"
          >
            <b-form-group
              :label="t('Invoice Use')"
              label-for="invoiceUse"
            >
              <v-select
                id="taxBandList"
                v-model="contactData.invoiceuse"
                :state="getValidationState(validationContext)"
                text-field="v"
                label="v"
                :options="invoiceuseList.filter(c => (c.pt.find(o => o === contactData.type) && c.sr.find(u => u === contactData.taxertype.k)))"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="divider my-2">
            <div class="divider-text">
              {{ t('Address') }}              
            </div>
          </div>

          <!-- ZIP -->
          <validation-provider
            #default="validationContext"
            name="ZIP"
            rules="required|min:5"
          >
            <b-form-group
              :label="t('Zip')"
              label-for="zip"
              :invalid-feedback="t('zipr')"
            >
              <b-form-input
                id="zip"
                v-model="contactData.address.zip"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                :formatter="fmt5num"
                @change="checkZip(contactData.address.zip)"
              />

              <!--<b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <b-form-group
            :label="t('Country')"
            label-for="countryList"
            
          >
            <v-select
              id="countryList"
              disabled
              v-model="contactData.address.country"
              text-field="v"
              label="v"
              :options="countryOption"
            />
          </b-form-group>

          <!-- State -->
          <b-form-group
            :label="t('State')"
            label-for="stateList"
            
          >
            <v-select
              id="stateList"
              disabled
              v-model="contactData.address.state"
              text-field="v"
              label="v"
              :options="stateOption"
            />
          </b-form-group>

          <!-- Town -->
          <b-form-group
            :label="t('Town')"
            label-for="townList"
            
          >
            <v-select
              id="townList"
              disabled
              v-model="contactData.address.town"
              text-field="v"
              label="v"
              :options="townOption"
            />
          </b-form-group>

          <!-- Suburb -->
          <b-form-group
            :label="t('Suburb')"
            label-for="suburbList"
            
          >
            <v-select
              id="suburbList"
              v-model="contactData.address.suburb"
              text-field="v"
              label="v"
              :options="suburbOption"
            />
          </b-form-group>

          <!-- Street -->
          <b-form-group
            :label="t('Street')"
            label-for="address-street"
            
          >
            <b-form-input
              id="street"
              v-model="contactData.address.street"
              
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ t('Add') }}   
              
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, VBTooltip, BInputGroupAppend, BInputGroup,BMedia,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, catalog } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import invoiceuseList from '@/@catalogs/sat/c_usocfdi'
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BInputGroupAppend,
    BMedia,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isAddNewContactSidebarActive',
    event: 'update:is-add-new-contact-sidebar-active',
  },
  props: {
    isAddNewContactSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      catalog,
      stateOption: [],
      townOption: [],
      suburbOption: [],
      countryOption: [{ k: 'MX', v: 'Mexico' }],
      invoiceuseList,
      taxpayerList,
      /** fer@arkeline.io Para el control de las features disponibles */
      session: null
    }
  },
  setup(props, { emit }) {
    const blankContactData = {
      taxid: '', // RFC
      name: '', // Razon Social
      type: '', // Tipo de Persona
      taxertype: { // Regimen Fiscal
        k: '',
        v: ''
      },
      invoiceuse: { // Uso de la Factura
        k: '',
        v: ''
      },
      email: '', // Correo Electronico
      phone: '', // Telefono
      address: {
        street: '',
        suburb: {
          k: '',
          v: ''
        },
        town: {
          k: '',
          v: ''
        },
        state: {
          k: '',
          v: ''
        },
        country: {
          k: 'MEX',
          v: 'Mexico'
        },
        meta: {
          sat: {
            country: '',
            state: '',
            town: '',
            suburb: '',
            st: {
              m: 0,
              a: '',
              h: '',
              gmt: 0
            },
            wt: {
              m: 0,
              a: '',
              h: '',
              gmt: 0
            }
          }
        },
        zip: '',
      },
      //status: 'enabled',
      //ac:  0, 
      //au:  Math.round((new Date()).getTime() / 1000)
    }

    const contactData = ref(JSON.parse(JSON.stringify(blankContactData)))
    const resetcontactData = () => {
      contactData.value = JSON.parse(JSON.stringify(blankContactData))
    }

    const onSubmit = () => {
      // Hay que validar antes el envio
      store.dispatch('fg-contact/addContact', contactData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-contact-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontactData)

    /** lsandoval: 26/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    return {
      contactData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      t,
    }
  },
  mounted() {
    this.session = JSON.parse(localStorage.getItem('session'))
  },
  methods: {
    fmtUppercase(value) {
        let v = value.toUpperCase()
        v = v.length > 13 ? v.substr(0,13) : v
        return v
    },
    // ILL: 270122 para que la longitud del campo phone sea max = 10
    fmt10num(value) {        
        let v=value
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },
    // ILL: 270122 para que la longitud del campo zip sea max = 5
    fmt5num(value) {        
        let v=value
        v = v.length > 5 ? v.substr(0,5) : v
        return v
    },    
    checkZip (zip) {
      // Hay que validar antes el envio
      this.$store.dispatch('fg-contact/lookupZip', zip )
        .then((response) => {
          const bundle = response.data.bundle
          
          this.stateOption = [bundle.state]
          this.townOption = [bundle.town]
          this.suburbOption = [...bundle.suburbs]

          this.contactData.address.state = bundle.state
          this.contactData.address.town = bundle.town
        }).catch((error) => {
          
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
